import React, { Component } from "react";
import RoutesView from "./RoutesView";

class RoutesContainer extends Component {
  render() {
    return <RoutesView />;
  }
}

export default RoutesContainer;
