import React, { Component } from "react";
import { MyPortfolioView } from "../views";

class MyPortfolioContainer extends Component {
  render() {
    return <MyPortfolioView />;
  }
}

export default MyPortfolioContainer;
